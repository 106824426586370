import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/splide/dist/css/splide.min.css'
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import {FormattedMessage} from "react-intl";

function Popular() {

    const [popular, setPopular] = useState([])
    useEffect(() => {
        getPopular();
    }, []);
    const getPopular = async () => {
        const response = await fetch(process.env.REACT_APP_BACKEND_LOCATION+"/getRecipesRandom");
        if (response.redirected) {
            document.location = response.url;
        }
        const data = await response.json();
        setPopular(data);
    }
    return (
        <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Wrapper>
                <h3><FormattedMessage id="popular.title" defaultMessage="Popular picks" /></h3>
                <Splide options={{
                    perPage:4,
                    arrows: false,
                    pagination: false,
                    drag: 'free',
                    gap: "5rem",
                }}>
                    {popular.map((recipe) => {
                        return (
                            <SplideSlide key={recipe.id}>
                                <Card>
                                    <Link to={"/recipe/show/" + recipe.id}>
                                        <p>{recipe.titleDutch}</p>
                                        {recipe.imageData !== null&& (
                                            <img src={'data:image/jpeg;base64,' + recipe.imageData} alt={recipe.titleDutch}/>
                                        )}
                                        {recipe.imageData === null && (
                                            <img src={process.env.REACT_APP_UNKNOWN_IMAGE} alt={recipe.titleDutch}/>
                                        )}
                                        <Gradient/>
                                    </Link>
                                </Card>
                            </SplideSlide>
                        );
                    })}
                </Splide>
            </Wrapper>
        </motion.div>
    )
}
const Wrapper = styled.div`
    margin: auto;
    text-align: center;
`

const Card = styled.div`
    height: 5rem;
    width: 5rem;
    border-radius: 2rem;
    overflow: hidden;
    position: relative;
    img{
        border-radius: 2rem;
        position: absolute;
        left: 0;
        width: 5rem;
        height: 5rem;
        object-fit: cover;
    }
    p{
        position: absolute;
        z-index: 10;
        left: 50%;
        bottom: 0%;
        transform: translate(-50%, 0%);
        color: white;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 0.7rem;
        height: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const Gradient = styled.div`
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
`

export default Popular;