import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {motion} from "framer-motion";
import {WiTime5} from "react-icons/wi";
import {PiCookingPotDuotone} from "react-icons/pi";
import {FaFire, FaMinusSquare, FaPlusSquare} from "react-icons/fa";
import {MdKeyboardBackspace} from "react-icons/md";
import {TbPencilMinus} from "react-icons/tb";
import Title from "../components/Title";
import {Context} from "../components/Wrapper";
import {FormattedMessage} from "react-intl";
import {useSwipeable} from "react-swipeable";

function Recipe() {
    const context = useContext(Context);
    const navigate = useNavigate();
    let params = useParams();
    const nbrServingsDefault = 4;
    const [recipe, setRecipe] = useState({});
    const [activeTab, setActiveTab] = useState("Ingredients");
    const [currentServings, setCurrentServings] = useState(4)
    const [instructionsDone, setInstructionsDone] = useState([])
    const [nutricientsTotal, setNutricientsTotal] = useState(0)
    const [chocdf, setChocdf] = useState(0)
    const [enercKcal, setEnercKcal] = useState(0)
    const [fat, setFat] = useState(0)
    const [fibtg, setFibtg] = useState(0)
    const [procnt, setProcnt] = useState(0)
    const [sugar, setSugar] = useState(0)
    const fetchDetails = async ()=> {
        const response = await fetch(process.env.REACT_APP_BACKEND_LOCATION + `/recipe?recipeId=${params.id}`);
        const detailData = await response.json();
        if (detailData) {
            setRecipe(detailData)
            setCalorySums(detailData)
        }
    }
    function setCalorySums(recipeRetrieved){
        let nutricientsTotal = 0;
        let chocdf = 0;
        let enercKcal = 0;
        let fat = 0;
        let fibtg = 0;
        let procnt = 0;
        let sugar = 0;
        for(let i = 0; i < recipeRetrieved.ingredients?.length; i++) {
            nutricientsTotal += recipeRetrieved.ingredients[i].nutrients?.calories;
            chocdf += recipeRetrieved.ingredients[i].nutrients?.totalNutrients?.chocdf.quantity
            enercKcal += recipeRetrieved.ingredients[i].nutrients?.totalNutrients?.enercKcal.quantity
            fat += recipeRetrieved.ingredients[i].nutrients?.totalNutrients?.fat.quantity
            fibtg += recipeRetrieved.ingredients[i].nutrients?.totalNutrients?.fibtg.quantity
            procnt += recipeRetrieved.ingredients[i].nutrients?.totalNutrients?.procnt.quantity
            sugar += recipeRetrieved.ingredients[i].nutrients?.totalNutrients?.sugar.quantity
        }
        setNutricientsTotal(round(nutricientsTotal/nbrServingsDefault,0));
        setChocdf(round(chocdf/nbrServingsDefault,0));
        setEnercKcal(round(enercKcal/nbrServingsDefault,0));
        setFat(round(fat/nbrServingsDefault,0));
        setFibtg(round(fibtg/nbrServingsDefault,0));
        setProcnt(round(procnt/nbrServingsDefault,0));
        setSugar(round(sugar/nbrServingsDefault,0));
    }

    const servingDecrease = () => {
        if(currentServings > 1){
            setCurrentServings(currentServings - 1);
        }
    };
    const servingIncrease = () => {
        setCurrentServings(currentServings + 1);
    };

    function getQuantity(quantity)  {
        return round(quantity/nbrServingsDefault*currentServings,2);
    }
    function getQuantityFor1(quantity)  {
        return round(quantity/nbrServingsDefault,2);
    }
    function round(num, decimalPlaces = 0) {
        if (num < 0)
            return -round(-num, decimalPlaces);
        var p = Math.pow(10, decimalPlaces);
        var n = (num * p).toPrecision(15);
        return Math.round(n) / p;
    }
    const instructionStepsDoneClick = (event) => {
        if (event.target.checked === true && instructionsDone.some(item => item === ''+event.target.value)) {
            removeItem(instructionsDone, event.target.value)
            event.target.checked = false;
        }
    }
    function removeItem(arr, value) {
        const index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        setInstructionsDone(arr)
    }
    const instructionStepsDoneChange = (event) => {
        setInstructionsDone(prevState =>  [...prevState, event.target.value])
    }

    function checkIfInstructionStepIsDone(value) {

        return instructionsDone.some(item => item === ''+value) ? 'checked' : ''
    }
    const getNextRecipe = async ()=> {
        const response = await fetch(process.env.REACT_APP_BACKEND_LOCATION + `/getNextRecipe?recipeId=${params.id}`);
        const detailData = await response.json();
        if (detailData) {
            navigate(`/recipe/show/${detailData.id}`)
        }
    }
    const getPreviousRecipe = async ()=> {
        const response = await fetch(process.env.REACT_APP_BACKEND_LOCATION + `/getPreviousRecipe?recipeId=${params.id}`);
        const detailData = await response.json();
        if (detailData) {
            navigate(`/recipe/show/${detailData.id}`)
        }
    }

    useEffect(() => {
        fetchDetails()
    }, [params.id]);

    const handlers = useSwipeable({
        onSwipedLeft: (eventData) =>
            getNextRecipe(),
        onSwipedRight: (eventData) =>
            getPreviousRecipe()
    });

    return (
        <DetailWrapper {...handlers}
            // animate={{opacity: 1}}
            // initial={{opacity: 0}}
            // exit={{opacity: 0}}
            // transition={{duration: 0.5}}
        >
            <NavTop>
                <Logo to={"/"}> <MdKeyboardBackspace /></Logo>
            </NavTop>
            <Title />
            <Info>
                <InfoContent>
                    <h1>{context.locale === 'nl' ? recipe.titleDutch : recipe.titleEnglish}</h1>
                    <Source><FormattedMessage id="recipe.by" defaultMessage="Door" />: {recipe.createdBy}</Source>
                    <Indications>
                        <div>
                            <WiTime5/> {recipe.cookingTime} mins
                        </div>
                        <div>
                            {/*<PiCookingPot />*/}
                            <PiCookingPotDuotone/> <FormattedMessage id="recipe.level.medium" defaultMessage="Gemiddeld" />
                            {/*<PiCookingPotFill />*/}
                        </div>
                        <div>
                            {/*<FaFire/> {recipe?.ingredients.reduce((sum, {nutrients?.calories}) => sum + b.nutrients?.calories, 0)}*/}
                            <FaFire/>{nutricientsTotal > 0 ? nutricientsTotal : "TBD"} Kcal per persoon
                            {/*{recipe?.ingredients.map(item => item?.nutrients?.calories)*/}
                            {/*.reduce((prev, curr) => prev + curr, 0)}*/}
                            {/*{List.extend(recipe?.ingredients).select('nutrients?.calories').sum()}*/}

                        </div>
                    </Indications>
                    {recipe.descriptionDutch !== null && (
                        <Description>
                            <h2><FormattedMessage id="recipe.description" defaultMessage="Omschrijving" /></h2>
                            {context.locale === 'nl' ? recipe.descriptionDutch : recipe.descriptionEnglish}
                        </Description>
                    )}
                    <Actions>
                        <Button key="Ingredients"
                            className={activeTab === 'Ingredients' ? 'active' : ''}
                            onClick={() => setActiveTab("Ingredients")}><FormattedMessage id="recipe.ingredients" defaultMessage="Ingredienten" /></Button>
                        <Button key="Instructions"
                            className={activeTab === 'Instructions' ? 'active' : ''}
                            onClick={() => setActiveTab("Instructions")}><FormattedMessage id="recipe.instructions" defaultMessage="Bereiding" /></Button>
                        <Button key="nutrients"
                            className={activeTab === 'nutrients' ? 'active' : ''}
                            onClick={() => setActiveTab("nutrients")}><FormattedMessage id="recipe.nutrients" defaultMessage="Voedingswaarden" /></Button>
                    </Actions>
                    {activeTab === 'Ingredients' && (
                        <Ingredients>
                            <Servings>
                                <ServingButton onClick={() => servingDecrease()}><FaMinusSquare /></ServingButton> {currentServings} <FormattedMessage id="recipe.servings" defaultMessage="Personen" /> <ServingButton onClick={() => servingIncrease()}><FaPlusSquare /></ServingButton>
                            </Servings>
                                    {recipe?.ingredients?.map((ingredient, index) => {
                                        const prevGroupName = recipe.ingredients[index -1]?.groupName
                                        return (
                                            <div key={ingredient?.id}>

                                                {prevGroupName !== ingredient?.groupName && ingredient?.groupName && (
                                                    <IngredientGroup>Voor de {ingredient?.groupName}:</IngredientGroup>
                                                )}
                                                <IngredientList >
                                                    <div>{context.locale === 'nl'
                                                            ? ingredient.quantity > 1
                                                                ? ingredient.grocery?.nameGroceryDutchPlural
                                                                    ? ingredient.grocery?.nameGroceryDutchPlural
                                                                    : ingredient.grocery?.nameGroceryDutch
                                                                : ingredient.grocery?.nameGroceryDutch
                                                            : ingredient.quantity > 1
                                                                ? ingredient.grocery?.nameGroceryEnglishPlural
                                                                    ? ingredient.grocery?.nameGroceryEnglishPlural
                                                                    : ingredient.grocery?.nameGroceryEnglish
                                                                : ingredient.grocery?.nameGroceryEnglish}
                                                        {ingredient.preparation !== null && (
                                                            <span> ({context.locale === 'nl' ? ingredient.preparation.namePreparationDutch : ingredient.preparation.namePreparationEnglish})</span>
                                                        )}
                                                        {/*-- {ingredient.nutrients?.calories}*/}
                                                    </div>
                                                    {ingredient?.quantity > 0 && (
                                                        <IngredientQuantity>
                                                            {getQuantity(ingredient.quantity)} {ingredient?.unit?.abbreviationDutch !== null
                                                                ? context.locale === 'nl'
                                                                    ? ingredient.unit?.abbreviationDutch
                                                                    : ingredient.unit?.abbreviationEnglish
                                                                : (ingredient.quantity > 1
                                                                    ? context.locale === 'nl'
                                                                        ? ingredient.unit.nameUnitDutchPlural
                                                                        : ingredient.unit.nameUnitEnglishPlural
                                                                    : context.locale === 'nl'
                                                                        ? ingredient.unit.nameUnitDutch
                                                                        : ingredient.unit.nameUnitEnglish)}
                                                        </IngredientQuantity>
                                                    )}
                                                </IngredientList>
                                            </div>
                                        )
                                    })}
                        </Ingredients>
                    )}
                    {activeTab === 'Instructions' && (
                        <Instructions>
                            {recipe?.instructions?.map((instruction) => {
                                return (
                                    <label key={instruction.id}>
                                        <input id={instruction.id}
                                               value={instruction.id}
                                               checked={checkIfInstructionStepIsDone(instruction.id)}
                                               onClick={(e) => instructionStepsDoneClick(e)}
                                               onChange={(e) => instructionStepsDoneChange(e)} type="radio"/>
                                        <span>{instruction.descriptionDutch}</span>
                                    </label>
                                )
                            })}
                        </Instructions>
                    )}
                    {activeTab === 'nutrients' && (
                        <Nutrients>
                            <b>Voedingswaarden per persoon</b>
                            <NutrientList>
                                <StyledUl className="m-d">
                                    <StyledLi data-md-content="1000">
                                        <label name="tab" htmlFor="tab1" tabIndex="-1" className="tab_lab" role="tab">Energy: {enercKcal} Kcal</label>
                                        <input type="checkbox" className="tab" id="tab1" tabIndex="0"/>
                                        <span className="open-close-icon">
                                            <span className="fas fa-plus">+</span>
                                            <span className="fas fa-minus">-</span>
                                        </span>
                                        <div className="content">
                                                {recipe?.ingredients?.map((ingredient, index) => {
                                                    const prevGroupName = recipe.ingredients[index -1]?.groupName
                                                    return (
                                                        <div key={ingredient?.id}>

                                                            {prevGroupName !== ingredient?.groupName && ingredient?.groupName && (
                                                                <IngredientGroup>Voor de {ingredient?.groupName}:</IngredientGroup>
                                                            )}
                                                            <IngredientList >
                                                                <div>
                                                                    {getQuantityFor1(ingredient.quantity)} {ingredient?.unit?.abbreviationDutch !== null
                                                                        ? context.locale === 'nl'
                                                                            ? ingredient.unit?.abbreviationDutch
                                                                            : ingredient.unit?.abbreviationEnglish
                                                                        : (ingredient.quantity > 1
                                                                            ? ingredient.unit.nameUnitDutchPlural
                                                                            : ingredient.unit.nameUnitDutch)} {ingredient.grocery?.nameGroceryDutch}
                                                                </div>
                                                                <IngredientQuantity>{round(ingredient.nutrients?.totalNutrients?.enercKcal.quantity/4,2)} Kcal
                                                                </IngredientQuantity>
                                                            </IngredientList>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </StyledLi>
                                    <StyledLi data-md-content="1000">
                                        <label name="tab" htmlFor="tab2" tabIndex="-1" className="tab_lab" role="tab">Total lipid (fat): {fat} gram</label>
                                        <input type="checkbox" className="tab" id="tab2" tabIndex="0"/>
                                        <span className="open-close-icon"><span className="fas fa-plus">+</span><span
                                            className="fas fa-minus">-</span></span>
                                        <div className="content">
                                                {recipe?.ingredients?.map((ingredient, index) => {
                                                    const prevGroupName = recipe.ingredients[index -1]?.groupName
                                                    return (
                                                        <div key={ingredient?.id}>

                                                            {prevGroupName !== ingredient?.groupName && ingredient?.groupName && (
                                                                <IngredientGroup>Voor de {ingredient?.groupName}:</IngredientGroup>
                                                            )}
                                                            <IngredientList >
                                                                <div>
                                                                    {getQuantityFor1(ingredient.quantity)} {ingredient?.unit?.abbreviationDutch !== null
                                                                    ? context.locale === 'nl'
                                                                        ? ingredient.unit?.abbreviationDutch
                                                                        : ingredient.unit?.abbreviationEnglish
                                                                    : (ingredient.quantity > 1
                                                                        ? ingredient.unit.nameUnitDutchPlural
                                                                        : ingredient.unit.nameUnitDutch)} {ingredient.grocery?.nameGroceryDutch}
                                                                </div>
                                                                <IngredientQuantity>{round(ingredient.nutrients?.totalNutrients?.fat.quantity/4,2)} gram
                                                                </IngredientQuantity>
                                                            </IngredientList>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </StyledLi>
                                    <StyledLi data-md-content="1000">
                                        <label name="tab" htmlFor="tab3" tabIndex="-1" className="tab_lab"
                                               role="tab">Carbohydrate, by difference: {chocdf} gram</label>
                                        <input type="checkbox" className="tab" id="tab3" tabIndex="0"/>
                                        <span className="open-close-icon"><span className="fas fa-plus">+</span><i
                                            className="fas fa-minus">-</i></span>
                                        <div className="content">
                                            {recipe?.ingredients?.map((ingredient, index) => {
                                                const prevGroupName = recipe.ingredients[index -1]?.groupName
                                                return (
                                                    <div key={ingredient?.id}>

                                                        {prevGroupName !== ingredient?.groupName && ingredient?.groupName && (
                                                            <IngredientGroup>Voor de {ingredient?.groupName}:</IngredientGroup>
                                                        )}
                                                        <IngredientList >
                                                            <div>
                                                                {getQuantityFor1(ingredient.quantity)} {ingredient?.unit?.abbreviationDutch !== null
                                                                ? context.locale === 'nl'
                                                                    ? ingredient.unit?.abbreviationDutch
                                                                    : ingredient.unit?.abbreviationEnglish
                                                                : (ingredient.quantity > 1
                                                                    ? ingredient.unit.nameUnitDutchPlural
                                                                    : ingredient.unit.nameUnitDutch)} {ingredient.grocery?.nameGroceryDutch}
                                                            </div>
                                                            <IngredientQuantity>{round(ingredient.nutrients?.totalNutrients?.chocdf.quantity/4,2)} gram
                                                            </IngredientQuantity>
                                                        </IngredientList>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </StyledLi>
                                    <StyledLi data-md-content="1000">
                                        <label name="tab" htmlFor="tab4" tabIndex="-1" className="tab_lab"
                                               role="tab">Sugars, total including NLEA: {sugar} gram</label>
                                        <input type="checkbox" className="tab" id="tab4" tabIndex="0"/>
                                        <span className="open-close-icon"><span className="fas fa-plus">+</span><span
                                            className="fas fa-minus">-</span></span>
                                        <div className="content">
                                            {recipe?.ingredients?.map((ingredient, index) => {
                                                const prevGroupName = recipe.ingredients[index -1]?.groupName
                                                return (
                                                    <div key={ingredient?.id}>

                                                        {prevGroupName !== ingredient?.groupName && ingredient?.groupName && (
                                                            <IngredientGroup>Voor de {ingredient?.groupName}:</IngredientGroup>
                                                        )}
                                                        <IngredientList >
                                                            <div>
                                                                {getQuantityFor1(ingredient.quantity)} {ingredient?.unit?.abbreviationDutch !== null
                                                                ? context.locale === 'nl'
                                                                    ? ingredient.unit?.abbreviationDutch
                                                                    : ingredient.unit?.abbreviationEnglish
                                                                : (ingredient.quantity > 1
                                                                    ? ingredient.unit.nameUnitDutchPlural
                                                                    : ingredient.unit.nameUnitDutch)} {ingredient.grocery?.nameGroceryDutch}
                                                            </div>
                                                            <IngredientQuantity>{round(ingredient.nutrients?.totalNutrients?.sugar.quantity/4,2)} gram
                                                            </IngredientQuantity>
                                                        </IngredientList>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </StyledLi>
                                    <StyledLi data-md-content="1000">
                                        <label name="tab" htmlFor="tab5" tabIndex="-1" className="tab_lab"
                                               role="tab">Fiber, total dietary: {fibtg} gram</label>
                                        <input type="checkbox" className="tab" id="tab5" tabIndex="0"/>
                                        <span className="open-close-icon"><span className="fas fa-plus">+</span><span
                                            className="fas fa-minus">-</span></span>
                                        <div className="content">
                                            {recipe?.ingredients?.map((ingredient, index) => {
                                                const prevGroupName = recipe.ingredients[index -1]?.groupName
                                                return (
                                                    <div key={ingredient?.id}>

                                                        {prevGroupName !== ingredient?.groupName && ingredient?.groupName && (
                                                            <IngredientGroup>Voor de {ingredient?.groupName}:</IngredientGroup>
                                                        )}
                                                        <IngredientList >
                                                            <div>
                                                                {getQuantityFor1(ingredient.quantity)} {ingredient?.unit?.abbreviationDutch !== null
                                                                ? context.locale === 'nl'
                                                                    ? ingredient.unit?.abbreviationDutch
                                                                    : ingredient.unit?.abbreviationEnglish
                                                                : (ingredient.quantity > 1
                                                                    ? ingredient.unit.nameUnitDutchPlural
                                                                    : ingredient.unit.nameUnitDutch)} {ingredient.grocery?.nameGroceryDutch}
                                                            </div>
                                                            <IngredientQuantity>{round(ingredient.nutrients?.totalNutrients?.fibtg.quantity/4,2)} gram
                                                            </IngredientQuantity>
                                                        </IngredientList>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </StyledLi>
                                    <StyledLi data-md-content="1000">
                                        <label name="tab" htmlFor="tab6" tabIndex="-1" className="tab_lab"
                                               role="tab">Protein: {procnt} gram</label>
                                        <input type="checkbox" className="tab" id="tab6" tabIndex="0"/>
                                        <span className="open-close-icon"><span className="fas fa-plus">+</span><span
                                            className="fas fa-minus">-</span></span>
                                        <div className="content">
                                            {recipe?.ingredients?.map((ingredient, index) => {
                                                const prevGroupName = recipe.ingredients[index -1]?.groupName
                                                return (
                                                    <div key={ingredient?.id}>

                                                        {prevGroupName !== ingredient?.groupName && ingredient?.groupName && (
                                                            <IngredientGroup>Voor de {ingredient?.groupName}:</IngredientGroup>
                                                        )}
                                                        <IngredientList >
                                                            <div>
                                                                {getQuantityFor1(ingredient.quantity)} {ingredient?.unit?.abbreviationDutch !== null
                                                                ? context.locale === 'nl'
                                                                    ? ingredient.unit?.abbreviationDutch
                                                                    : ingredient.unit?.abbreviationEnglish
                                                                : (ingredient.quantity > 1
                                                                    ? ingredient.unit.nameUnitDutchPlural
                                                                    : ingredient.unit.nameUnitDutch)} {ingredient.grocery?.nameGroceryDutch}
                                                            </div>
                                                            <IngredientQuantity>{round(ingredient.nutrients?.totalNutrients?.procnt.quantity/4,2)} gram
                                                            </IngredientQuantity>
                                                        </IngredientList>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </StyledLi>
                                </StyledUl>
                            </NutrientList>
                            <NutrientNote>
                                Ter info: Deze voedingswaarden zijn slechts een schatting gebaseerd op de zover bekende
                                informatie.
                                Gebaseerd op informatie van Edamam. Slechts een indicatie, advies van een professioneel
                                dietist kan hiermee nooit worden vervangen.
                            </NutrientNote>
                        </Nutrients>
                    )}
                </InfoContent>
            </Info>
            {recipe && (
                <NavBottom>
                    <Logo to={"/recipe/" + recipe.id}> <TbPencilMinus/></Logo>
                </NavBottom>
            )}
            <Image>
                {recipe.imageData
                    ? <img
                        src={'data:image/jpeg;base64,' + recipe.imageData}
                        alt={recipe.titleDutch}/>
                    : <img
                        src={process.env.REACT_APP_UNKNOWN_IMAGE}
                        alt={recipe.titleDutch}/>
                }
            </Image>
        </DetailWrapper>
    )
}

const NavTop = styled.div`
    position: fixed;
    border-radius: 50%;
    justify-content: flex-start;
    align-items: center;
    background: rgb(250, 237, 247);

    svg {
        font-size: 1.5rem;
    }

    left: 1rem;
    top: 1rem;
    padding: 0.3rem;
`
const NavBottom = styled.div`
    position: fixed;
    background: white;
    justify-content: flex-start;
    align-items: center;
    border-radius: 50%;

    svg {
        font-size: 1.5rem;
    }

    bottom: 1rem;
    right: 1rem;
    padding: 0.3rem;
`

const Logo = styled(Link)`
    justify-content: center;
    text-align: center;
    color: black;
`
const IngredientGroup = styled.div`
    font-weight: bold;
`
const DetailWrapper = styled.div`
`
const Image = styled(motion.div)`
    display: block;

    img {
        width: 100%;
        object-fit: cover;
        height: 15rem;
        aspect-ratio: 4/3;
        display: block;
        top: 0;
    }
`

const Source = styled.div`
    font-size: 0.7rem;
    line-height: 0.8rem;
    color: grey;
    margin-top: 0.2rem;
`

const Indications = styled.div`
    color: grey;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
`
const Ingredients = styled.div`
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    width: 100%;
    font-size: 1rem;
`
const Nutrients = styled.div`
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    width: 100%;
    font-size: 1rem;
`
const IngredientList = styled.div`
    //display: flex;
    //justify-content: space-between;
    display: grid;
    grid-template-columns: 4fr 2fr;
    margin-bottom: 0.5rem;
`
const NutrientList = styled.div`
    //display: flex;
    //justify-content: space-between;
    //display: grid;
    //grid-template-columns: 4fr 2fr 0.3fr;
    margin-bottom: 0.5rem;
`
const IngredientQuantity = styled.div`
    text-align: right;
`
const NutrientQuantity = styled.div`
    text-align: right;
`

const Servings = styled.div`
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    svg{
        font-size: 1.5rem;
        color: rgb(198, 30, 156);
    }
`
const Description = styled.div`
    margin-bottom: 0.5rem;
    h2 {
        font-size: 1.2rem;
        line-height: 1.3rem;
        margin: 0;
    }
`
const ServingButton = styled.button`
    border: none;
    background-color: transparent;
    //padding: 0.5rem 0.5rem;
    //background: rgb(198, 30, 156);
    //border: 2px solid rgb(198, 30, 156);
    //color: white;
    //font-weight: 600;
    //border-radius: 0.8rem;
`

const Instructions = styled.div`
    input{
        font-size: 0.5rem;
        //margin-top: 1rem;
        position: absolute;
        top: 0;
        left: 0;
    }
    input:checked + span{
        color: grey;
    }
    input[type='radio'] {
        filter: grayscale(1)
    }
    span{
        display: block;
        //margin-top: 0;
        margin-left: 30px;
    }
    label{
        //border: 1px solid black;
        //margin-left: 2rem;
        position: relative;
        display: block;
        margin-bottom: 0.5rem;
    }
`
const Button = styled.button`
    padding: 0.5rem 0.5rem;
    background: rgb(198, 30, 156);
    border: 2px solid rgb(198, 30, 156);
    color: white;
    font-weight: 600;
    border-radius: 0.8rem;
`

const Actions = styled.div`
    display: flex;
    justify-content:space-evenly;
    justify-items: center;
    margin-bottom: 1rem;
    .active {
        background: white;
    color: rgb(198, 30, 156);
    }
`

const Info = styled.div`
    position: absolute;
    width: 100%;
    margin-top: 13rem;
    background: rgb(250, 237, 247);
    border-radius: 2rem;
    font-size: 0.9rem;
    h1{
        font-size: 1.5rem;
    }
`

const InfoContent = styled.div`
    padding-left: 1rem;
    padding-right: 1rem;
`

const NutrientNote = styled.div`
    font-size: 0.8rem;
    font-style: italic;
`

const StyledUl = styled.ul`
    margin: 0;
    padding: 0;
    label[class^="tab"]:hover{
        cursor: pointer;
    }
    input{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    input[class^="tab"]{
        width: 100%;
        //height: 40px;
        position: absolute;
        left: 0;
        top: 0;
    }
    input[class^="tab"]:hover{
        cursor: pointer;
    }
    label[class^="tab"]{
        //font-weight: bold;
    }
    .content{
        margin-left: 40px;
        height: auto;
        max-height: 0;
        //max-width: 300px;
        /*        background: yellow;*/
        overflow: hidden;
        //transform: translateY(20px);
        transition: all 180ms ease-in-out 0ms;
    }
    li[data-md-content="1000"] input[class^="tab"]:checked ~ .content{
        max-height: 1000px;
        transition: all 350ms ease-in-out 0ms;
    }
    li[data-md-content=""] input[class^="tab"]:checked ~ .content{
        max-height: 1000px;
        transition: all 250ms ease-in-out 0ms;
    }
    input[class^="tab"]:checked ~ .content{
        margin-bottom: 10px;
    }

    .open-close-icon{
        display: inline-block;
        position: absolute;
        right: 0px;
        //transform: translatey(2px);
    }
    .open-close-icon span{
        position: absolute;
        left: 0;
    }
    .open-close-icon .fa-minus{
        transform:rotate(-90deg);
        transition: transform 150ms ease-in-out 0ms;
    }
    input[class^="tab"]:checked ~ .open-close-icon .fa-minus{
        transform: rotate(0deg);
        transition: transform 150ms ease-in-out 0ms;
    }
    .open-close-icon .fa-plus{
        opacity: 1;
        transform:rotate(-90deg);
        transition: opacity 50ms linear 0ms, transform 150ms ease-in-out 0ms;
    }
    input[class^="tab"]:checked ~ .open-close-icon .fa-plus{
        opacity: 0;
        transform: rotate(0deg);
        transition: opacity 50ms linear 0ms, transform 150ms ease-in-out 0ms;
    }

`
const StyledLi = styled.li`
    list-style-type: none;
    padding: 2px 0;
    //border-bottom: 1px solid #212121;
    position: relative;
    /*         max-width: 80%; */
`


export default Recipe