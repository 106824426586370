import styled from "styled-components";
import {useState} from "react";
import {FaSearch} from "react-icons/fa";
import {useNavigate, useParams} from "react-router-dom";

function Search() {

    const [ input, setInput] = useState("");
    let params = useParams();
    const navigate = useNavigate()
    const submitHandler = (e) => {
        e.preventDefault();
        navigate("/searched/" + input)
    }
    return (
        <FormStyle onSubmit={submitHandler}>
            <div>
                <FaSearch />
                <input onChange={(e) => setInput(e.target.value)}
                       type="text"
                       defaultValue={params.searchTerm}/>
            </div>
        </FormStyle>
    )
}

const FormStyle = styled.form`
    margin-top: 0.5rem;
    div{
        position: relative;
    }
    input{
        margin: auto;
        display: block;
        text-align: center;
        width: 65%;
        //background: linear-gradient(35deg, #494949, #313131);
        background: white;
        font-size: 1.5rem;
        color: rgb(198, 30, 156);
        border: none;
        padding: 1rem 3rem;
        border-radius: 1rem;
        outline: none;
        //width: 100%;
    }
    svg {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(100%, -50%);
        color: rgb(198, 30, 156);
    }
`
export default Search