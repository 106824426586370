import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Search from "../components/Search";
import MyActions from "../components/MyActions";
import {motion} from "framer-motion";
import Title from "../components/Title";

function Searched() {
    let params = useParams();
    const [searchedRecipes, setSearchedRecipes] = useState([]);

    const getSearched = async (searchTerm)=> {
        if (searchTerm) {
            const requestOptions = {
                method: `GET`,
                redirect: "follow",
                // credentials: 'include'
            };
            const response = await fetch(process.env.REACT_APP_BACKEND_LOCATION +  `/search?searchTerm=${searchTerm}`, requestOptions);
            if (response.redirected) {
                document.location = response.url;
            }
            const recipes = await response.json();
            setSearchedRecipes(recipes)
        }
    };

    useEffect(() => {
        getSearched(params.searchTerm)
    }, [params.searchTerm]);
    return (
        <Wrapper>
            <Content>
                <Title />
                <Search />
                <Grid
                    // animate={{ opacity: 1 }}
                    // initial={{ opacity: 0 }}
                    // exit={{ opacity: 0 }}
                    // transition={{ duration: 0.5 }}
                >
                    {searchedRecipes && (
                        searchedRecipes.map((recipe) => {
                            return (
                                <Card key={recipe.id}>
                                    <Link to={"/recipe/show/" + recipe.id}>
                                        <Gradient/>
                                        <p>{recipe.titleDutch}</p>
                                        {recipe.imageData !== null&& (
                                            <img src={'data:image/jpeg;base64,' + recipe.imageData} alt={recipe.titleDutch}/>
                                        )}
                                        {recipe.imageData === null && (
                                            <img src={process.env.REACT_APP_UNKNOWN_IMAGE} alt={recipe.titleDutch}/>
                                        )}
                                    </Link>
                                </Card>
                            );
                        })
                    )}
                </Grid>
            </Content>
            <Buffer></Buffer>
            <MyActions />
        </Wrapper>
    )
}

const Card = styled(motion.div)`
    position: relative;
    img{
        border-radius: 2rem;
        //border-radius: 0.5rem 0.5rem 0 0;
        width: 100%;
        object-fit: cover;
        height: 10rem;
        aspect-ratio: 4/3;
        display: block;
    }
    p{
        position: absolute;
        bottom: 0;
        z-index: 100;
        left: 50%;
        transform: translate(-50%, 0%);
        color: white;
        width: 80%;
        text-align: center;
        font-weight: 500;
        font-size: 1rem;
        justify-content: center;
    }
`;

const Gradient = styled.div`
     position: absolute;
     width: 100%;
     height: 100%;
    border-radius: 2rem;
//rgb(198, 30, 156)
//     background: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(251, 92, 5, 0.5) 100%);
     background: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0, 0, 0, 0.2) 100%);
`

const Grid = styled.div`
    list-style: none;
    padding: 0;
    margin-top: 2rem;
    //margin: auto;
    display: grid;
    //width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(9.5rem, 1fr));
    //grid-template-columns: masonry;
    //grid-template-rows: repeat(4, 1fr);
    grid-gap: 1rem;
`
const Content = styled.div`
    width: 90%;
    justify-items: center;
    margin: auto;
`
const Wrapper = styled.div`
`
const Buffer = styled.div`
    height: 80px;
`
export default Searched;