import {GiHotMeal} from "react-icons/gi";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import {PiBreadBold} from "react-icons/pi";
import {LuDessert} from "react-icons/lu";
import {CgBowl} from "react-icons/cg";
import {TbEggs} from "react-icons/tb";
import {FormattedMessage} from "react-intl";

function Category() {
    return (
        <div>
            <Splide options={{
                perPage:6,
                arrows: false,
                pagination: false,
                drag: 'free',
                gap: "5rem",
            }}>
                <SplideSlide>
                    <SLink to={"/cuisine/Ontbijt"}>
                        <TbEggs/>
                        <p><FormattedMessage id="category.breakfast" defaultMessage="Ontbijt" /></p>
                    </SLink>

                </SplideSlide>
                <SplideSlide>
                    <SLink to={"/cuisine/Hoofdgerecht"}>
                        <GiHotMeal/>
                        <p><FormattedMessage id="category.dinner" defaultMessage="Hoofdgerecht" /></p>
                    </SLink>
                </SplideSlide>
                <SplideSlide>
                    <SLink to={"/cuisine/Lunch"}>
                        <PiBreadBold/>
                        <p><FormattedMessage id="category.lunch" defaultMessage="Lunch" /></p>
                    </SLink>
                </SplideSlide>
                <SplideSlide>
                    <SLink to={"/cuisine/Dessert"}>
                        <LuDessert/>
                        <p><FormattedMessage id="category.dessert" defaultMessage="Dessert" /></p>
                    </SLink>
                </SplideSlide>
                <SplideSlide>
                    <SLink to={"/cuisine/Bijgerecht"}>
                        <CgBowl/>
                        <p><FormattedMessage id="category.sidedish" defaultMessage="Sidedish" /></p>
                    </SLink>
                </SplideSlide>
            </Splide>
        </div>
    );
}

const SLink = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border-radius: 50%;
    border-radius: 1rem;
    margin-right: 1rem;
    text-decoration: none;
    //background: linear-gradient(35deg, #494949, #313131);
    background: white;
    width: 5rem;
    height: 5rem;
    cursor: pointer;
    transform: scale(0.8);
    p{
        color: rgb(198, 30, 156);
        font-size: 0.8rem;
        line-height: 0.5rem;
    }
    svg{
        color: rgb(198, 30, 156);
        font-size: 1.5rem;
        line-height: 1rem;
    }
    &.active{
        //background: linear-gradient(to right, rgb(251, 92, 5), rgb(198, 30, 156));
        background: rgb(198, 30, 156);
        color: white;
        svg{
            color: white;
        }
        p{
            color: white;
        }
    }
`

export default Category;