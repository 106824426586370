import Pages from "./pages/Pages";
import {BrowserRouter} from "react-router-dom";
import React from "react";
import './variables.css';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Pages/>

            </BrowserRouter>
        </div>
    );
}

export default App;