export function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.authdata) {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + user.authdata
        };
    } else {
        return {};
    }
}

export function authHeaderWithoutContentType() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.authdata) {
        return {
            'Accept': 'application/json',
            'Authorization': 'Basic ' + user.authdata
        };
    } else {
        return {};
    }
}