import React, {useState} from "react";
import English from "../lang/en.json";
import Dutch from "../lang/nl.json";
import {IntlProvider} from "react-intl";

export const Context = React.createContext();
const local = navigator.language;

let lang;
if (local==="en-GB") {
    lang = English;
} else {
    lang = Dutch;
}
const Wrapper = (props) => {
    const defaultLanguage = process.env.REACT_APP_LANGUAGE_ENABLED === 'true' ? local === 'en-GB' ? 'en' : 'nl' : 'nl'
    const [locale, setLocale] = useState(defaultLanguage);

    const language = process.env.REACT_APP_LANGUAGE_ENABLED === 'true' ? lang : Dutch
    const [messages, setMessages] = useState(language);

    function selectLanguage(e) {
        // console.log(e)
        const newLocale = e
        setLocale(newLocale);
        if(newLocale === 'nl') {
            setMessages(Dutch);
        } else {
            setMessages(English);
        }
    }
    return (
        <Context.Provider value={{locale, selectLanguage}}>
            <IntlProvider locale ={locale} messages={messages}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    )
}

export default Wrapper