import React, {useContext, useEffect, useState} from 'react'
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import MyLogo from "../logo.png";
import NlFlag from "../nl.svg";
import UkFlag from "../gb.svg";
import {Context} from "./Wrapper"
function Title() {
    const context = useContext(Context);

    const [histo, setHistoData] = useState([["recipeId", "calories"]])
    useEffect(() => {
        getHistoData();
    }, []);
    const getHistoData = async () => {
        const response = await fetch(process.env.REACT_APP_BACKEND_LOCATION+"/getRecipesOrderedByCalories");
        const data = await response.json();
        const arr = Object.keys(data).map((key) => [key, data[key]]);
        setHistoData([["recipeId","Calories"], ...arr]);
    }
    return (
        <Nav>
            {useLocation().pathname === '/' && (
                <Logo to={"/"}> <img src={MyLogo} alt="Logo" height={80}/></Logo>
            )}
            {useLocation().pathname.includes('/searched') && (
                <Logo to={"/"}> <img src={MyLogo} alt="Logo" height={80}/></Logo>
            )}
            {useLocation().pathname.includes('/cuisine') && (
                <Logo to={"/"}> <img src={MyLogo} alt="Logo" height={80}/></Logo>
            )}
            {process.env.REACT_APP_LANGUAGE_ENABLED === 'true' && (
                <LanguageFlags>
                    <img
                        className={context.locale === 'nl' ? 'active' : 'inactive'}
                        src={NlFlag}
                        alt="Nederlands"
                        onClick={(e) => context.selectLanguage('nl')} />
                    <img
                        className={context.locale === 'en' ? 'active' : 'inactive'}
                        src={UkFlag}
                        alt="English"
                        onClick={(e) => context.selectLanguage('en')} />
                </LanguageFlags>
            )}
        </Nav>
    );
}

const Nav = styled.div`
    //margin-top: 1rem;
    display: flex;
    //justify-content: flex-end;
    align-items: center;
    svg {
        font-size: 2rem;
    }
    //border-bottom: 3px dotted black;
`
const Logo = styled(Link)`
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 400;
`
const LanguageFlags = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    //text-align: right;
    //justify-content: flex-end;
    img{
        border-radius: 50%;
        width: 2rem;
        margin-left: 10px;
        &.active {
            opacity: 90%;
        }
        &.inactive {
            opacity: 50%;
        }
    }
`

export default Title;