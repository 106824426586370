import Popular from "../components/Popular";
import {motion} from "framer-motion";
import React, {useEffect, useState} from 'react'
import Search from "../components/Search";
import Category from "../components/Category";
import styled from "styled-components";
import MyActions from "../components/MyActions";
import Title from "../components/Title";
import Histogram from "../components/Histogram";
import RangeSlider from "../components/RangeSlider";
function Home() {

    const [histoData, setHistoData] = useState([["recipeId", "calories"]])
    const [nbrOfRecipes, setNbrOfRecipes] = useState(0)
    useEffect(() => {
        getHistoData();
    }, []);
    const getHistoData = async () => {
        const response = await fetch(process.env.REACT_APP_BACKEND_LOCATION+"/getRecipesOrderedByCalories");
        const data = await response.json();

        const arr = Object.keys(data).map((key) => [key, data[key]]);

        setNbrOfRecipes(Object.keys(data).length);
        setHistoData([["recipeId","Calories"], ...arr]);
    }

    const range = [0, 2000];

    return (
        <Body
            animate={{opacity: 1}}
            initial={{opacity: 0}}
            exit={{opacity: 0}}
            transition={{duration: 0.5}}
        >
            <Content>
                <Title />
                <Search/>
                <Category/>
                <Popular/>
                <br />
                Recepten gerankschikt op caloriewaarden
                <Histogram data = {histoData} />
                Aantal volledige recepten: {nbrOfRecipes}
                {/*<RangeSlider*/}
                {/*    values={range}*/}
                {/*    mode={2}*/}
                {/*    step={1}*/}
                {/*    domain={range}*/}
                {/*    // onChange={onChangeCallBack}*/}
                {/*    // onUpdate={onUpdateCallBack}*/}
                {/*/>*/}
            </Content>
            <Buffer></Buffer>
            <MyActions/>
        </Body>
    );
}

const Body = styled(motion.div)`
`
const Content = styled.div`
    width: 90%;
    justify-items: center;
    margin: auto;
`
const Buffer = styled.div`
    height: 80px;
`

export default Home;