import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {GoHeart, GoPerson, GoPersonFill, GoPlus} from "react-icons/go";
import {IoHomeOutline, IoHomeSharp} from "react-icons/io5";
import {RiSearchFill, RiSearchLine} from "react-icons/ri";

function MyActions() {
    const [user, setUser] = useState([]);
    // console.log(this.context.router.isActive(this.props.to, true))
    // let isActive = this.context.router.isActive(this.props.to, true);
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')));
    }, []);
    return (
        <Panel>
            <ButtonBar>
                <div className="tab tab--left">
                    <ActionLinks to={"/"}>
                        {useLocation().pathname === '/' ? <IoHomeSharp />: <IoHomeOutline />}
                    </ActionLinks>
                    <ActionLinks to={"/searched/"}>
                    {useLocation().pathname.includes("/searched") ? <RiSearchFill /> : <RiSearchLine />}


                    {/*<NavLink className={({ isActive }) => (isActive ? 'active-link' : 'link')} to="/home">Home</NavLink>*/}
                    {/*<IoHomeSharp />*/}
                    </ActionLinks>
                </div>
                <div className="tab tab--fab">
                    <Background>
                        <MainAction>
                            <Logo to={"/recipe/new"}> <GoPlus/></Logo>
                        </MainAction>
                    </Background>
                </div>
                <div className="tab tab--right">
                    <GoHeart/>
                    {/*<GoHeartFill/>*/}
                    {user && (
                        <ActionLinks to={"/profile"}><GoPersonFill /></ActionLinks>
                    )}
                    {!user && (
                        <ActionLinks to={"/profile"}><GoPerson /></ActionLinks>
                    )}
                </div>
            </ButtonBar>
        </Panel>
    )
}

const Panel = styled.div`
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
`

const ButtonBar = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;

    .tab {
        &--left {
            color: rgb(198, 30, 156);
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;
            border-top-right-radius: 30px;
            border-top: 1px solid rgba(167, 161, 161, 0.69);
            box-shadow: 0px 6px 7px 0px rgba(23, 23, 23, 0.28);
        }
        &--right {
            color: rgb(198, 30, 156);
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;
            border-top-left-radius: 30px;
            border-top: 1px solid rgba(167, 161, 161, 0.69);
            box-shadow: 15px 4px 15px 0px rgba(23, 23, 23, 0.28);
        }
        &--fab {
            width: 180px;
            height: 100%;
            background: transparent;
            border: none;
            display: flex;
        }
        background-color: #fff;
        width: 33.3%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-top: 1px solid #eee;
        box-shadow: 1x 1x 3px #ccc, -1px -1px 3px #ccc;
        font-size: 24px;
        span {
            font-size: 12px;
        }
        i {
            font-size: 24px;
        }
    }
`
const Background = styled.div`
    width: 100%;
    height: 50%;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    background-color: transparent;
    box-shadow: 0px 30px 0px 25px #fff;
    border-bottom: 1px solid rgba(167, 161, 161, 0.69);
    display: flex;
`
const MainAction = styled.div`
    border-radius: 50%;
    background-color: rgb(198, 30, 156);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    font-weight: bold;
    font-size: 22px;
    color: #fff;
    position: relative;
    transform: translate(2px,-60%);
`

const Logo = styled(Link)`
    color: white;
    svg {
        font-size: 2rem;
    }
`

const ActionLinks = styled(Link)`
    color: rgb(198, 30, 156);
`

export default MyActions