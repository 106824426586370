import React from 'react'
import Home from "./Home";
import {Routes, Route, useLocation} from "react-router-dom";
import Cuisine from "./Cuisine";
import Searched from "./Searched";
import Recipe from "./Recipe";
import {AnimatePresence} from "framer-motion";
import CreateRecipe from "./CreateRecipe";
import {LoginPage} from "./LoginPage";
import Profile from "./Profile";
import PrivateRoute from "../components/PrivateRoute";

function Pages() {
    const location = useLocation();
    return (
        <AnimatePresence mode={"wait"}>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="/cuisine/:type" element={<Cuisine />} />
                <Route path="/searched/:searchTerm" element={<Searched />} />
                <Route path="/searched/" element={<Searched />} />
                <Route path="/recipe/show/:id" element={<Recipe/>} />
                <Route element={<PrivateRoute/>}>
                    <Route path="/recipe/:id" element={<CreateRecipe/>} />
                </Route>
                <Route path="/login" element={<LoginPage/>} />
                <Route path="/profile" element={<Profile/>} />
                {/*<Route path="/signup" element={ <SignupPage/>} />*/}
            </Routes>
        </AnimatePresence>
    );
}

export default Pages